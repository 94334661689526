import {Modal} from 'flowbite';

document.addEventListener("DOMContentLoaded", async () => {
    const deviceId = 1; // Certifique-se que deviceId seja o correto.

    // Ouve o canal de inatividade do dispositivo
    Echo.channel(`device-inactivity.${deviceId}`)
        .listen('DeviceInactivityWarning', (e) => {
            console.log('DeviceInactivityWarning Echo :: ', e);
            showModal(e.message);
        });

    // Função para mostrar o modal
    window.showModal = (message) => {
        const $targetEl = document.getElementById('activity-confirmation');
        const options = {
            placement: 'bottom-right',
            backdrop: 'dynamic',
            backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
            closable: true,
        };

        // Inicializa o modal sem o terceiro parâmetro (instanceOptions)
        const modal = new Modal($targetEl, options);

        // Atualiza a mensagem do modal
        const modalMessage = document.getElementById('activity-confirmation-message');
        modalMessage.innerText = message;

        // Exibe o modal
        modal.show();

        // Defina o timeout para desconectar o dispositivo se não houver resposta
        const responseTimeout = 60000; // 60 segundos
        const timeoutId = setTimeout(() => {
            axios.post('/disconnect-device', {
                device_id: deviceId
            })
                .then(() => {
                    console.log('Dispositivo desconectado por inatividade.');
                })
                .catch((error) => {
                    console.error('Erro ao desconectar dispositivo:', error);
                });
        }, responseTimeout);

        // Manter a referência do timeout para cancelá-lo, se necessário
        $targetEl.setAttribute('data-timeout-id', String(timeoutId));
    }

    window.handleUserResponse = async (stillUsing) => {
        const modal = document.getElementById('activity-confirmation');
        const timeoutId = modal.getAttribute('data-timeout-id');

        if (stillUsing) {
            // O usuário ainda está utilizando o dispositivo, manter ativo
            try {
                await axios.post('/keep-device-active', {
                    device_id: deviceId
                });
                console.log('Dispositivo mantido ativo.');
                clearTimeout(Number(timeoutId)); // Cancela o timeout de desconexão
            } catch (error) {
                console.error('Erro ao manter dispositivo ativo:', error);
            }
        } else {
            // O usuário não está mais utilizando o dispositivo, desconectar
            try {
                await axios.post('/disconnect-device', {
                    device_id: deviceId
                });
                location.reload(); // Recarrega a página após a desconexão
            } catch (error) {
                console.error('Erro ao desconectar dispositivo:', error);
            }
            clearTimeout(Number(timeoutId)); // Cancela o timeout de desconexão
        }
    }


});
